.lazyframe {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.lazyframe__title {
  z-index: 3;
  padding: 15px 17px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.lazyframe__title:after {
  z-index: -1;
}

.lazyframe:hover {
  cursor: pointer;
}

.lazyframe:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.lazyframe[data-ratio="16:9"]:before {
  padding-top: 56.25%;
}

.lazyframe[data-ratio="4:3"]:before {
  padding-top: 75%;
}

.lazyframe[data-ratio="1:1"]:before {
  padding-top: 100%;
}

.lazyframe iframe {
  z-index: 5;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.lazyframe[data-vendor="youtube"], .lazyframe[data-vendor="youtube_nocookie"] {
  background-color: #e52d27;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.lazyframe[data-vendor="youtube"] .lazyframe__title, .lazyframe[data-vendor="youtube_nocookie"] .lazyframe__title {
  color: #eee;
  text-shadow: 0 0 2px #0000007f;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: #0000;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 18px;
  transition: color .1s cubic-bezier(.4, 0, 1, 1);
}

.lazyframe[data-vendor="youtube"] .lazyframe__title:hover, .lazyframe[data-vendor="youtube_nocookie"] .lazyframe__title:hover {
  color: #fff;
}

.lazyframe[data-vendor="youtube"] .lazyframe__title:before, .lazyframe[data-vendor="youtube_nocookie"] .lazyframe__title:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  -webkit-tap-highlight-color: #0000;
  background: linear-gradient(#0003, #0000);
  width: 100%;
  height: 98px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.lazyframe[data-vendor="youtube"]:before, .lazyframe[data-vendor="youtube_nocookie"]:before {
  padding-top: 56.25%;
}

.lazyframe[data-vendor="youtube"]:after, .lazyframe[data-vendor="youtube_nocookie"]:after {
  content: "";
  opacity: .81;
  z-index: 4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231F1F1F' d='M255.7 446.3c-53.3.3-106.6-.4-159.8-3.3-17.4-1-34.7-2.5-50.4-11C35 426.3 27 418.4 22 407.2 13.2 388.6 10.5 369 9 349c-3.4-41.3-3.6-82.6-1.8-123.8 1-22 1.6-44 6.8-65.5 2-8.4 5-16.6 8.8-24.4C32 117 48 108 67.3 104c16.2-3 32.8-3 49.3-3.7 56-2.3 112-3.5 168-3 43 .6 86.2 1.7 129.3 4 13.2.6 26.6.8 39.3 5.5 17.2 6.4 30 17.2 37 34.7 6.6 16.8 9.2 34.2 10.6 52 3.8 48.7 4 97.3.7 146-1 16.3-2.2 32.7-6.5 48.8-9.7 37-32.8 51.5-66.7 53.8-36.2 2.5-72.5 3.8-108.8 4.3-21.3.2-42.7 0-64 0zM203.2 344L348 264.7l-144.8-79.3V344z'/%3E%3Cpath fill='%23FEFDFD' d='M203.2 344V185.5L348 264.8 203.2 344z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  width: 68px;
  height: 48px;
  margin-top: -24px;
  margin-left: -34px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.lazyframe[data-vendor="youtube"]:hover:after, .lazyframe[data-vendor="youtube_nocookie"]:hover:after {
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23DD2C28' d='M255.7 446.3c-53.3.3-106.6-.4-159.8-3.3-17.4-1-34.7-2.5-50.4-11C35 426.3 27 418.4 22 407.2 13.2 388.6 10.5 369 9 349c-3.4-41.3-3.6-82.6-1.8-123.8 1-22 1.6-44 6.8-65.5 2-8.4 5-16.6 8.8-24.4C32 117 48 108 67.3 104c16.2-3 32.8-3 49.3-3.7 56-2.3 112-3.5 168-3 43 .6 86.2 1.7 129.3 4 13.2.6 26.6.8 39.3 5.5 17.2 6.4 30 17.2 37 34.7 6.6 16.8 9.2 34.2 10.6 52 3.8 48.7 4 97.3.7 146-1 16.3-2.2 32.7-6.5 48.8-9.7 37-32.8 51.5-66.7 53.8-36.2 2.5-72.5 3.8-108.8 4.3-21.3.2-42.7 0-64 0zM203.2 344L348 264.7l-144.8-79.3V344z'/%3E%3Cpath fill='%23FEFDFD' d='M203.2 344V185.5L348 264.8 203.2 344z'/%3E%3C/svg%3E");
}

.lazyframe[data-vendor="vimeo"] {
  background-color: #00adef;
}

.lazyframe[data-vendor="vimeo"] .lazyframe__title {
  color: #00adef;
  text-rendering: optimizeLegibility;
  -webkit-user-select: none;
  user-select: none;
  -webkit-font-smoothing: auto;
  -webkit-tap-highlight-color: #0000;
  background-color: #00000080;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.lazyframe[data-vendor="vimeo"]:before {
  padding-top: 48.25%;
}

.lazyframe[data-vendor="vimeo"]:after {
  content: "";
  z-index: 3;
  background-color: #00000080;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' baseProfile='tiny' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' d='M7.765 16.89l8.47-4.89-8.47-4.89'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
  width: 65px;
  height: 40px;
  display: block;
  position: relative;
  bottom: 10px;
  left: 10px;
}

.lazyframe[data-vendor="vimeo"]:hover:after {
  background-color: #00adef;
}
/*# sourceMappingURL=repo.e3413cf6.css.map */
